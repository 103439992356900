<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="container fn text-white">
      <div>
        <div class="mb-1">
          <router-link :to="{ name: 'dashboard-hitler' }">
            <div class="all-title-head">
              ข้อมูลเบื้องต้น
            </div>
            <div class="dtx2">
              <span>
                <i
                  class="fas fa-angle-left fa-2x"
                  style="color: #fff;"
                />

              </span>
            </div>
          </router-link>
        </div>
        <div>
          <div>
            <div class="LoginForm">
              <!-- <label class="mt-1">ข้อมูลส่วนตัว</label> -->
              <u class="mt-1"> ข้อมูลส่วนตัว </u>
              <p
                class="ml-1 mt-1"
                style="font-weight: 300"
              >
                <i class="fas fa-user-circle mr-1" />{{ User.name }}
                {{ User.surname }}
              </p>
              <p
                class="ml-1 mt-1"
                style="font-weight: 300"
              >
                <i class="fas fa-phone-square mr-1" />{{ User.tel }}
              </p>

              <div class="mt-2">
                <u> บัญชีผู้ใช้งาน </u>
                <p
                  class="ml-1 mt-1"
                  style="font-weight: 300"
                >
                  <i class="fas fa-id-card-alt mr-1" />{{ User.username }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- <hr> -->
        <div class="all-title-head mb-1">
          ข้อมูลบัญชีของท่าน
        </div>
        <!-- <div class="p-1">

          <h4>ข้อมูลบัญชี</h4>
        </div> -->
        <div
          v-if="User.trueaccno"
          class="balance-cl"
        >
          <div class="d-flex justify-content-between">
            <div>
              <h6 style="color: #000">
                ทรูมันนี่วอลเล็ท
              </h6>
              <p style="font-size: 1.5rem; color: #ff7b00">
                {{ User.trueaccno }}
              </p>

              <h6 style="color: #000">
                ชื่อบัญชี
              </h6>
              <h6 style="color: #ff7b00">
                {{ User.name }} {{ User.surname }}
              </h6>
            </div>
            <div>
              <img
                src="/bankIcon/wallet.png"
                alt=""
                height="65"
              >
            </div>
          </div>
        </div>

        <br><br>
        <div v-if="User.acc_no">
          <div
            class="card7"
            :style="`background-color: ${User.bg};`"
          >
            <!-- <div
          class="card"
          :style="`background-color: ${AgentData.bg};`"
        > -->
            <div class="logo">
              <img
                :src="`/bankIcon/${User.path_photo}`"
                alt="logo-bank"
                height="75"
                style="float: right"
                class="mt-2"
              >
            </div>
            <div class="chip">
              <img
                src="/icon/chip.png"
                alt="chip"
              >
              <span class="bankk">{{ User.bankname }}</span>
            </div>
            <div class="number">
              {{ User.acc_no }}
            </div>
            <div class="name">
              {{ User.name }} {{ User.surname }}
            </div>
            <div class="from">
              <!-- THAILAND -->
            </div>
            <div class="to">
              <!-- 06/23 -->
            </div>
            <div class="ring" />
          </div>
        </div>
        <hr>
        <div class="text-center mt-2 fn">
          <small class="text-warning">
            *หมายเหตุ โปรดใช้บัญชีนี้เท่านั้นในการฝาก-ถอนเท่านั้น
          </small>
        </div>
        <button
          class="btnlogout mb-4 mt-1"
          @click="logout"
        >
          ออกจากระบบ
        </button>

        <br>
        <br>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BIconController,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'

export default {
  components: {
    BOverlay,
    BIconController,
  },
  data() {
    return {
      show: true,
      selected: [],
      userData: JSON.parse(localStorage.getItem('userData')),
      User: '',
    }
  },
  mounted() {
    this.getUser(this.userData)
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('CountLogin')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push('/login')
    },
    getUser(userData) {
      this.show = true
      this.$http
        .get(`https://api.ufabangkok.com/api/users/show/${userData.userID}`)
        .then(response => {
          // console.log(response.data.ListGames)
          this.show = false
          this.User = response.data
        })
        .catch(error => console.log(error))
    },
  },
}
</script>

<style scoped>

.container {
  max-width: 540px;
}
.LoginForm {
    width: 100%;
    /* margin: auto; */
    padding: 11px 15px 31.5px;
    /* opacity: 0.59; */
    border-radius: 10px;
    background-color: #050202;
    margin-top: 40px;
    background: rgba(0,0,0,0.59);
}
p {
  color: rgb(255, 255, 255);
}
a {
  color: #fff;
}
i {
  color: rgb(255, 255, 255);
}
.dtx2 {
  position: relative;
  display: flex;
  margin-left: 5px;
  margin-top: -38px;
  justify-content: left;
  color: #000;
  font-weight: 600;
  align-items: left;
}
.cs {
  background: linear-gradient(to bottom, #7222416e, #58585870);
  border-radius: 10px;
  /* color: #fff; */
  padding: 0.5rem;
  border: rgba(255, 255, 255, 0.527) solid 3px;
  /* box-shadow: #8826ff94 0px 5px 10px 5px; */
}
.circle {
  position: absolute;
  border-radius: 50%;
  background: radial-gradient(#006db3, #29b6f6);
}
.circles {
  position: absolute;
  height: 270px;
  width: 450px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.circle-1 {
  height: 180px;
  width: 180px;
  top: -50px;
  left: -60px;
}
.circle-2 {
  height: 200px;
  width: 200px;
  bottom: -90px;
  right: -90px;
  opacity: 0.8;
}
/* Background circles end */

/* .card-group {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.card7 {
  margin: auto;
  position: relative;
  height: 210px;
  max-width: 380px;
  border-radius: 15px;
  /* background: rgb(163, 0, 0); */
  /* background-color: #4e2e7f; */
  backdrop-filter: blur(30px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.logo img,
.chip img,
.number,
.bankk,
.name,
.from,
.to,
.ring {
  position: absolute; /* All items inside card should have absolute position */
}

.logo img {
  top: 15px;
  right: 10px;
  width: 80px;
  height: auto;
  opacity: 0.8;
}

.chip img {
  top: 60px;
  left: 20px;
  width: 50px;
  height: auto;
  opacity: 0.8;
}
.bankk {
  top: 20px;
  left: 20px;
  font-size: 1.3rem;
  color: #fff;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
}

.number,
.name,
.from,
.to {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  letter-spacing: 2px;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
}

.number {
  left: 20px;
  font-size: 1.5rem;
  bottom: 65px;
  font-family: 'Nunito', sans-serif;
}

.name {
  font-size: 1rem;
  left: 20px;
  bottom: 35px;
}

.from {
  font-size: 1rem;
  bottom: 35px;
  right: 90px;
}

.to {
  font-size: 1rem;
  bottom: 35px;
  right: 20px;
}

/* The two rings on the card background */
.ring {
  height: 500px;
  width: 500px;
  border-radius: 50%;
  background: transparent;
  border: 50px solid rgba(255, 255, 255, 0.1);
  bottom: -250px;
  right: -250px;
  box-sizing: border-box;
}

.ring::after {
  content: '';
  position: absolute;
  height: 600px;
  width: 600px;
  border-radius: 50%;
  background: transparent;
  border: 30px solid rgba(255, 255, 255, 0.1);
  bottom: -80px;
  right: -110px;
  box-sizing: border-box;
}
.profile-on-image {
  text-align: center;
  background-image: url(/Low/pro.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  padding: 50px 40px;
}
/* asdasd */
</style>

<style lang="scss" scoped>
.form-control {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 6px;
}
/* asdasd */
</style>
